﻿import * as TYPES from '../mutation-types';
import { REQUEST_STATUS, PREFERENCES_SECTION } from '../../config/constants';
import { PREFERENCES } from '../getters';
import { GET_PREFERENCES, SET_PREFERENCES } from '../action-types';
import * as preferencesApi from '@api/preferences';
import { addUserLink, getUserLinks } from '@api/userLinks';
import { resetState } from '../StoreHelpers';
import { MODULE_DEFAULT, TRAVELSECTIONS_DEFAULT, JOBSECTIONS_DEFAULT } from '@config/preferenceDefaults.js';

export const initialState = () => ({
    modules: {
        data: {
            modules: null
        },
        status: null
    },
    travelSections: {
        data: [],
        status: null
    },
    jobSections: {
        data: [],
        status: null
    },
    userLinks: {
        data: [],
        status: null
    },
    generalData: {}
});

const mutations = {
    [TYPES.PREFERENCES_RESET](state) {
        resetState(state, initialState());
    },

    [TYPES.PREFERENCES_REQUEST](state, section) {
        setSectionStatus(state, section, REQUEST_STATUS.REQUEST);
    },

    [TYPES.PREFERENCES_SUCCESS](state, section) {
        setSectionStatus(state, section, REQUEST_STATUS.SUCCESS);
    },

    [TYPES.PREFERENCES_FAILURE](state, section) {
        setSectionStatus(state, section, REQUEST_STATUS.FAILURE);
    },

    /**
     * Assign value to the state for the specific section
     * @param {any} state
     * @param {any} payload
     */
    [TYPES.MODULE_PREFERENCES](state, payload) {
        const section = PREFERENCES_SECTION.MODULE;
        setSectionData(state, section, payload);
        setSectionStatus(state, section, REQUEST_STATUS.SUCCESS);
    },

    [TYPES.TRAVELSECTIONS_PREFERENCES](state, payload) {
        const section = PREFERENCES_SECTION.TRAVELSECTIONS;
        setSectionData(state, section, payload);
        setSectionStatus(state, section, REQUEST_STATUS.SUCCESS);
    },

    [TYPES.JOBSECTIONS_PREFERENCES](state, payload) {
        const section = PREFERENCES_SECTION.JOBSECTIONS;
        setSectionData(state, section, payload);
        setSectionStatus(state, section, REQUEST_STATUS.SUCCESS);
    },

    [TYPES.USERLINKS_PREFERENCES](state, payload) {
        const section = PREFERENCES_SECTION.USERLINKS;
        setSectionData(state, section, payload);
        setSectionStatus(state, section, REQUEST_STATUS.SUCCESS);
    },

    /**
     * Add content for an preference key to the state
     * @param {any} state
     * @param {Object} value
     * @param {String} value.key The preference key
     * @param {Object} value.content The preference content as { data, status }
     */
    [TYPES.PREFERENCES_GENERAL_DATA_SET](state, value) {
        state.generalData[value.key] = value.content;
    },
};

const actions = {

    // Module Preferences
    async [GET_PREFERENCES.MODULE]({ commit }) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.MODULE);
        try {
            const preferences = await preferencesApi.getModulePreferences();
            if (!preferences) {
                // user has no preference - set to default
                commit(TYPES.MODULE_PREFERENCES, deepClone(MODULE_DEFAULT));
            } else {
                // append any missing types from the default list
                const { sections: currentPreference } = preferences.modules;
                const { sections: defaultPreference } = MODULE_DEFAULT.modules;

                // put any missing keys at the beginning of the array
                const missingKeys = defaultPreference.filter(p => !currentPreference.includes(p));
                let newSections = new Set(missingKeys.concat(currentPreference));

                // remove any defunt sections
                currentPreference
                    .filter(p => !defaultPreference.includes(p))
                    .forEach(p => newSections.has(p) && newSections.delete(p));

                // apply the changes
                preferences.modules.sections = Array.from(newSections);

                commit(TYPES.MODULE_PREFERENCES, preferences);
            }
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.MODULE);
        }
    },

    async [SET_PREFERENCES.MODULE]({ commit }, preferences) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.MODULE);
        try {
            if (!preferences) {
                preferences = deepClone(MODULE_DEFAULT);
            }
            await preferencesApi.updateModulePreferences(preferences);
            commit(TYPES.MODULE_PREFERENCES, preferences);
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.MODULE);
        }
    },

    // Travel Sections
    async [GET_PREFERENCES.TRAVELSECTIONS]({ commit }) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.TRAVELSECTIONS);
        try {
            let userPreferences = await preferencesApi.getTravelSectionsPreference();

            // if we didn't get anything from the database - give the user the default sections
            if (!userPreferences || userPreferences.length === 0) {
                commit(TYPES.TRAVELSECTIONS_PREFERENCES, deepClone(TRAVELSECTIONS_DEFAULT));
            } else {
                let preferenceOrder = userPreferences.split(',');

                // apply the ordering
                if (preferenceOrder.length > 0) {
                    let orderedItems = [];

                    // find the sections that are in the user's preferences
                    preferenceOrder.map(prefId => {
                        let s = TRAVELSECTIONS_DEFAULT.find(ts => ts.id === prefId);
                        if (s) {
                            orderedItems.push(s);
                        }
                    });

                    // find the sections that were not in the user's preferences
                    TRAVELSECTIONS_DEFAULT.map(ts => {
                        // if the travel section is not in the ordered items
                        if (!orderedItems.some(oi => oi.id === ts.id)) {
                            orderedItems.push(ts);
                        }
                    });

                    commit(TYPES.TRAVELSECTIONS_PREFERENCES, orderedItems);
                } else {
                    // despite having data from the database it couldn't be mapped so give the user the default preferences
                    commit(TYPES.TRAVELSECTIONS_PREFERENCES, TRAVELSECTIONS_DEFAULT);
                }
            }
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.TRAVELSECTIONS);
        }
    },

    async [SET_PREFERENCES.TRAVELSECTIONS]({ commit }, preferences) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.TRAVELSECTIONS);
        try {
            if (preferences) {
                commit(TYPES.TRAVELSECTIONS_PREFERENCES, preferences);
                // we only want the id fields to save to the database
                let prefIds = preferences.map(p => p.id).join(',');
                await preferencesApi.updateTravelSectionsPreference(prefIds);
            } else {
                // reset to default
                commit(TYPES.TRAVELSECTIONS_PREFERENCES, deepClone(TRAVELSECTIONS_DEFAULT));
                await preferencesApi.updateTravelSectionsPreference('');
            }
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.TRAVELSECTIONS);
        }
    },

    // Job Sections
    async [GET_PREFERENCES.JOBSECTIONS]({ commit }) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.JOBSECTIONS);
        try {
            let userPreferences = await preferencesApi.getJobSectionsPreference();

            // if we didn't get anything from the database - give the user the default sections
            if (!userPreferences || userPreferences.length === 0) {
                commit(TYPES.JOBSECTIONS_PREFERENCES, deepClone(JOBSECTIONS_DEFAULT));
            } else {
                let preferenceOrder = userPreferences.split(',');

                // apply the ordering
                if (preferenceOrder.length > 0) {
                    let orderedItems = [];

                    // find the sections that are in the user's preferences
                    preferenceOrder.map(prefId => {
                        let s = JOBSECTIONS_DEFAULT.find(ts => ts.id === prefId);
                        if (s) {
                            orderedItems.push(s);
                        }
                    });

                    // find the sections that were not in the user's preferences
                    JOBSECTIONS_DEFAULT.map(ts => {
                        // if the job section is not in the ordered items
                        if (!orderedItems.some(oi => oi.id === ts.id)) {
                            orderedItems.push(ts);
                        }
                    });

                    commit(TYPES.JOBSECTIONS_PREFERENCES, orderedItems);
                } else {
                    // despite having data from the database it couldn't be mapped so give the user the default preferences
                    commit(TYPES.JOBSECTIONS_PREFERENCES, JOBSECTIONS_DEFAULT);
                }
            }
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.JOBSECTIONS);
        }
    },

    async [SET_PREFERENCES.JOBSECTIONS]({ commit }, preferences) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.JOBSECTIONS);
        try {
            if (preferences) {
                commit(TYPES.JOBSECTIONS_PREFERENCES, preferences);
                // we only want the id fields to save to the database
                let prefIds = preferences.map(p => p.id).join(',');
                await preferencesApi.updateJobSectionsPreference(prefIds);
            } else {
                // reset to default
                commit(TYPES.JOBSECTIONS_PREFERENCES, deepClone(JOBSECTIONS_DEFAULT));
                await preferencesApi.updateJobSectionsPreference('');
            }
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.JOBSECTIONS);
        }
    },

    // User Link Preferences
    async [GET_PREFERENCES.USERLINKS]({ commit }) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.USERLINKS);
        try {
            const preferences = await getUserLinks();
            commit(TYPES.USERLINKS_PREFERENCES, preferences);
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.USERLINKS);
        }
    },

    [SET_PREFERENCES.USERLINKS]({ commit }, preferences) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.USERLINKS);
        try {
            // just save to the store, something else will do the writing back elsewhere
            commit(TYPES.USERLINKS_PREFERENCES, preferences);
        } catch (e) {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.USERLINKS);
        }
    },

    async [SET_PREFERENCES.USERLINKS_ADD]({ commit, dispatch }, link) {
        commit(TYPES.PREFERENCES_REQUEST, PREFERENCES_SECTION.USERLINKS);
        try {
            await addUserLink(link);
            await dispatch(GET_PREFERENCES.USERLINKS);
            commit(TYPES.PREFERENCES_SUCCESS, PREFERENCES_SECTION.USERLINKS);
        } catch {
            commit(TYPES.PREFERENCES_FAILURE, PREFERENCES_SECTION.USERLINKS);
        }
    },

    /**
     * General Data GET
     * @param {String} key The preference key to retrieve data for
     */
    async [GET_PREFERENCES.GENERAL_DATA]({ commit }, key) {
        let preferenceData = {
            key: key,
            content: {
                data: null,
                status: REQUEST_STATUS.REQUEST
            }
        };
        try {
            commit(TYPES.PREFERENCES_GENERAL_DATA_SET, preferenceData); // save that we're starting to get the preferences
            let stringData = await preferencesApi.getPreference(key);
            preferenceData.content.data = JSON.parse(stringData);
            preferenceData.content.status = REQUEST_STATUS.SUCCESS;
            commit(TYPES.PREFERENCES_GENERAL_DATA_SET, preferenceData);
        } catch (e) {
            preferenceData.content.data = null;
            preferenceData.content.status = REQUEST_STATUS.FAILURE;
            commit(TYPES.PREFERENCES_GENERAL_DATA_SET, preferenceData);
        }
    },

    /**
     * General Data SET
     * @param {Object} value
     * @param {String} value.key The preference key
     * @param {Object} value.data The preference data
     */
    async [SET_PREFERENCES.GENERAL_DATA]({ commit }, value) {
        let preferenceData = {
            key: value.key,
            content: {
                data: value.data,
                status: REQUEST_STATUS.SUCCESS
            }
        };
        let submitDataString = JSON.stringify(preferenceData.content.data);
        try {
            commit(TYPES.PREFERENCES_GENERAL_DATA_SET, preferenceData);
            await preferencesApi.updatePreference(preferenceData.key, submitDataString);
        } catch (e) {
            // silently fail
        }
    }
};

const getters = {
    // the module preferences
    [PREFERENCES.modulePrefs]: state => state.modules.data,
    [PREFERENCES.modulePrefsStatus]: state => state.modules.status,

    // the travel section preferences
    [PREFERENCES.travelSectionsPrefs]: state => state.travelSections.data,
    [PREFERENCES.travelSectionsPrefsStatus]: state => state.travelSections.status,

    // the job section preferences
    [PREFERENCES.jobSectionsPrefs]: state => state.jobSections.data,
    [PREFERENCES.jobSectionsPrefsStatus]: state => state.jobSections.status,

    // the userLinks preferences
    [PREFERENCES.userLinksPrefs]: state => state.userLinks.data,
    [PREFERENCES.userLinksPrefsStatus]: state => state.userLinks.status,

    /**
     * Retrieve preference data by key
     * @param {String} key The preference key to get data for
     * @returns {Object} The preference content as { data, status }
     */
    [PREFERENCES.GENERAL_DATA]: state => key => {
        return state.generalData[key];
    },

};

/**
 * Set the data of a specified section in the state
 * @param {any} state 
 * @param {any} section The section to update
 * @param {any} dataValue The status value to assign
 */
function setSectionData(state, section, dataValue) {
    switch (section) {
    case PREFERENCES_SECTION.MODULE:
        state.modules.data = dataValue;
        break;
    case PREFERENCES_SECTION.TRAVELSECTIONS:
        state.travelSections.data = dataValue;
        break;
    case PREFERENCES_SECTION.JOBSECTIONS:
        state.jobSections.data = dataValue;
        break;
    case PREFERENCES_SECTION.USERLINKS:
        state.userLinks.data = dataValue;
        break;
    }
}

/**
 * Set the status of a specified section in the state
 * @param {any} state 
 * @param {any} section The section to update
 * @param {any} statusValue The status value to assign
 */
function setSectionStatus(state, section, statusValue) {
    switch (section) {
    case PREFERENCES_SECTION.MODULE:
        state.modules.status = statusValue;
        break;
    case PREFERENCES_SECTION.TRAVELSECTIONS:
        state.travelSections.status = statusValue;
        break;
    case PREFERENCES_SECTION.JOBSECTIONS:
        state.jobSections.status = statusValue;
        break;
    case PREFERENCES_SECTION.USERLINKS:
        state.userLinks.status = statusValue;
        break;
    }
}

/**
 * Local deep cloning to see how it goes in different browser versions...
 * @param {any} original
 */
function deepClone(original) {
    let clone = JSON.parse(JSON.stringify(original));
    return clone;
}

export default {
    state: initialState(),
    mutations,
    getters,
    actions
};