﻿/**
 * Specify the default model for preference types
 */

export const MODULE_DEFAULT = {
    modules: {
        spaceFilter: 'moodle',
        periodFilter: 'current',
        schemesObeyYearFilter: true,
        sections: ['programme', 'course', 'ready', 'category', 'dept', 'appraisal', 'digitalSkills', 'securityTrainingPriority', 'securityTrainingOptional', 'learnDev', 'other']
    }
};

export const MODULE_SECTION_LIST = {
    programme: {
        value: 'programme',
        text: 'Programme of Study'
    },
    course: {
        value: 'course',
        text: 'Course Modules'
    },
    ready: {
        value: 'ready',
        text: 'Ready for University'
    },
    category: {
        value: 'category',
        text: 'Category Enrolments'
    },
    dept: {
        value: 'prodeptgramme',
        text: 'Department'
    },
    appraisal: {
        value: 'appraisal',
        text: 'PGR Records'
    },
    digitalSkills: {
        value: 'digitalSkills',
        text: 'Digital Skills Certificate'
    },
    securityTrainingPriority: {
        value: 'securityTrainingPriority',
        text: 'Priority Security Training'
    },
    securityTrainingOptional: {
        value: 'securityTrainingOptional',
        text: 'Optional Security Training'
    },
    learnDev: {
        value: 'learnDev',
        text: 'Learning Development'
    },
    other: {
        value: 'other',
        text: 'Other Learning Spaces'
    }
};

export const TRAVELSECTIONS_DEFAULT = [
    {
        id: 'googleTraffic',
        title: 'Traffic Congestion',
        description: 'Highlights areas of road traffic congestion around Lancaster University campus.'
    },
    {
        id: 'railDisruption',
        title: 'Rail Disruption',
        description: 'Current disruption to rail services at Lancaster station.'
    }
];

export const JOBSECTIONS_DEFAULT = [
    {
        id: 'jb-graduate',
        type: 'job',
        title: 'Jobs - Graduate',
        description: 'Jobs and schemes for graduates.'
    },
    {
        id: 'ev-careers',
        type: 'event',
        title: 'Careers Events',
        description: 'Events run by the Careers Team.'
    },
    {
        id: 'jb-part-time',
        type: 'job',
        title: 'Jobs - Part-time Work',
        description: 'Part-time jobs available.'
    },
    {
        id: 'jb-training',
        type: 'job',
        title: 'Jobs - Training',
        description: 'Further study, training and development opportunities.'
    },
    {
        id: 'jb-placement',
        type: 'job',
        title: 'Jobs - Placement',
        description: 'Placement opportunities (e.g. year in industry/sandwich placements).'
    },
    {
        id: 'jb-vacation',
        type: 'job',
        title: 'Jobs - Vacation',
        description: 'Vacation and short internship opportunities.'
    },
    {
        id: 'jb-voluntary',
        type: 'job',
        title: 'Jobs - Voluntary',
        description: 'Voluntary opportunities.'
    },
    {
        id: 'jb-staff',
        type: 'job',
        title: 'Jobs - LU Staff',
        description: 'Staff jobs at Lancaster University.'
    }
];