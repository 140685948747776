﻿<template>
    <div class="org-js-mega-menu">
        <nav
            class="org-header__nav o-bg-primary-red o-bg-menu-grey--dark-mode"
            aria-label="navigation and search"
        >
            <div class="o-container o-container--original o-container--mega-menu">
                <div class="org-mega-menu org-grid-mega-menu">
                    <!--Fallback for when the menu data doesn't load for some reason - but main function is to keep the menu bar in the UI tidy and stop it from bouncig as data gets injected-->
                    <ul
                        v-if="!menu.length"
                        class="org-grid-mega-menu__griditem1"
                        role="menubar"
                        aria-label="primary navigation"
                    >
                        <li role="menuitem">
                            <a
                                href="/home"
                                class="org-mega-menu--root__link"
                                @click="sendGaEvent('Mega Menu', 'Home')"
                            >
                                Home
                            </a>
                        </li>
                        <li role="menuitem">
                            <a
                                href="/news"
                                class="org-mega-menu--root__link router-link-exact-active router-link-active"
                                @click="sendGaEvent('Mega Menu', 'News')"
                            >
                                News
                            </a>
                        </li>
                        <li role="menuitem">
                            <a
                                href="/my-area"
                                class="org-mega-menu--root__link"
                                @click="sendGaEvent('Mega Menu', 'My Area')"
                            >
                                My Area
                            </a>
                        </li>
                        <li role="menuitem">
                            <a
                                class="org-mega-menu--root__link"
                                href="https://portal.lancaster.ac.uk/ask"
                                @click="sendGaEvent('Mega Menu', 'ASK')"
                            >ASK</a>
                        </li>
                    </ul>
                    <!--actual menu data-->
                    <ul
                        v-else-if="menu.length"
                        class="org-grid-mega-menu__griditem1"
                        role="menubar"
                        aria-label="primary navigation"
                    >
                        <li
                            v-for="item in menu"
                            :key="item.id"
                            role="menuitem"
                        >
                            <router-link
                                v-if="item.url && item.internal"
                                :to="`/${item.url}`"
                                class="org-mega-menu--root__link"
                                :class="{'router-link-active': item.url === secondaryNavParent}"
                                :data-secondary-key="item.url"
                                @click="sendGaEvent('Mega Menu', item.name)"
                            >
                                {{ item.name }}
                            </router-link>
                            <a
                                v-else-if="item.url"
                                class="org-mega-menu--root__link"
                                parent-name="Mega Menu"
                                :href="item.url"
                                @click="sendGaEvent('Mega Menu', item.name)"
                            >
                                {{ item.name }}
                            </a>
                            <!--panel tabs-->
                            <a
                                v-else-if="!item.url"
                                :id="path(item)"
                                :href="`#${path(item)}`"
                                class="org-mega-menu--root__link"
                                data-mega-menu-tab
                                :data-mega-menu-tab-controls="`${path(item)}-panel`"
                            >
                                {{ item.name }}
                            </a>
                        </li>
                    </ul>
                    <div class="org-grid-mega-menu__griditem2">
                        <SearchBar class="u-width-100" />
                    </div>

                    <!-- hamburger -->
                    <div class="org-grid-mega-menu-mobile">
                        <button
                            id="mobile-mega-menu-open"
                            class="a-hamburger a-button--primary-red a-button-dark-grey--dark-mode u-bdr-0"
                            aria-label="Open the menu"
                            @click="mobileMenuButtonClick"
                        >
                            <span class="e-svg-hamburger">
                                <IconBars class="svg-inline--fa fa-w-14" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
        <!-- panels go here... -->
        <div
            v-for="item in panels"
            :id="`${path(item)}-panel`"
            :key="`${path(item)}-panel`"
            aria-label="menu panel"
            class="o-bg-menu-grey org-js-mega-menu-panel"
            role="navigation"
            tabindex="-1"
            data-mega-menu-panel
            hidden
        >
            <div class="o-container o-container--mega-menu-panel">
                <div class="org-mega-menu-categories org-grid-mega-menu-categories">
                    <div
                        v-for="child in item.children"
                        :key="child.name"
                        class="org-mega-menu-categories__category"
                    >
                        <h3>
                            <a
                                v-if="child.url"
                                href="#"
                            >
                                {{ child.name }}
                            </a>
                            <span v-else>
                                {{ child.name }}
                            </span>
                        </h3>
                        <ul
                            v-if="child.children.length"
                            :class="{'org-grid-mega-menu-categories--column': child.children.length > 8}"
                        >
                            <li
                                v-for="link in child.children"
                                :key="link.url"
                                class="org-mega-menu-categories__category__list"
                            >
                                <a
                                    :href="link.url"
                                    @click="sendGaEvent(`Mega Menu Panel : ${item.name}`, link.name)"
                                >
                                    {{ link.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconBars } from '@cisweb/icon-library-vue3';
import SearchBar from '@core-portal/components/molecules/SearchBar.vue';

import initMegaMenuPanels from '@core-portal/scripts/mega-menu-panels.helper';
import { sendCustomEvent } from '@utilities/AnalyticsHelper.js';
import { computed, watch, nextTick } from 'vue';
import { useMenuStore } from '@core-portal/stores/menu.module';
import { storeToRefs } from 'pinia';
import { EVENT_NAME } from '@config/constants';

export default {
    name: 'MegaMenuApp',
    components: {
        IconBars,
        SearchBar
    },
    emits: ['mobileMenuButtonClick'],
    setup(props, context) {
        // get the mega menu data from store
        const menuStore = useMenuStore();
        const { mega: menu, secondaryKey: secondaryNavParent } = storeToRefs(menuStore);
        const panels = computed(() => menu.value.filter(link => !link.url));

        const mobileMenuButtonClick = () => context.emit('mobileMenuButtonClick');
        watch(panels, async () => {
            if (panels.value.length) {
                await nextTick();
                initMegaMenuPanels();
            }
        });

        const path = (item) => {
            return (item.shortName || item.name).toLowerCase();
        };

        const sendGaEvent = (source, label) => {
            sendCustomEvent({
                'event': EVENT_NAME.NAVIGATION,
                'event_action_source': source,
                'event_label': label
            });
        };

        return {
            menu,
            panels,
            mobileMenuButtonClick,
            path,
            sendGaEvent,
            secondaryNavParent
        };
    }
};
</script>